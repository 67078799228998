<template>
    <div
        class="core-view d-flex flex-column transfers-builder"
        :class="{'menu-shown': !sideMenuHidden, 'menu-collapsed': !sideMenuHidden && sideMenuCollapsed, 'menu-expanded': !sideMenuHidden && !sideMenuCollapsed}"
        data-cy="transferBuilder"
    >
        <Loading
            v-if="fetching"
            active
            loader=""
            class="paglipat-loading-component"
        >
            <FontAwesomeIcon
                icon="spinner"
                size="5x"
                pulse
                class="mb-2"
            />

            <template
                slot="after"
            >
                <h3
                    class="text-muted"
                >
                    loading...
                </h3>
            </template>
        </Loading>

        <div v-if="!fetching">
            <PBuilderToolbar
                @add-step="createStep"
                @delete-transfer="transferDelete"
                @run-transfer="runTransferJob"
                @save-transfer="save"
            />

            <ValidationObserver
                ref="transferValidationObserver"
            >
                <form>
                    <div class="container-fluid mt-2 ml-auto mr-auto">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <ValidationProvider
                                    v-slot="{errors: transferTitleErrors}"
                                    ref="transferTitleValidator"
                                    name="Title"
                                    rules="required"
                                >
                                    <div
                                        :class="['form-group', {'is-invalid': transferTitleErrors.length}]"
                                    >
                                        <label>Title</label>

                                        <input
                                            v-model.trim="transferTitle"
                                            data-cy="transferTitle"
                                            type="text"
                                            :class="['form-control', {'is-invalid': transferTitleErrors.length}]"
                                            placeholder="Example Transfer"
                                        >
                                        <div
                                            class="error-message text-danger mt-1"
                                        >
                                            {{ transferTitleErrors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-4">
                                <ValidationProvider
                                    v-slot="{errors: transferCodeErrors}"
                                    ref="transferCodeValidator"
                                    name="Code"
                                    rules="required"
                                >
                                    <div
                                        :class="['form-group', {'is-invalid': transferCodeErrors.length}]"
                                    >
                                        <label>Code</label>

                                        <input
                                            v-model.trim="transferCode"
                                            data-cy="transferCode"
                                            type="text"
                                            :class="['form-control', {'is-invalid': transferCodeErrors.length}]"
                                            placeholder="example_transfer"
                                            @keypress="codeOverride = true"
                                        >
                                        <div
                                            class="error-message text-danger mt-1"
                                        >
                                            {{ transferCodeErrors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-2">
                                <ValidationProvider
                                    v-slot="{errors: scheduleNumberErrors}"
                                    ref="scheduleNumberValidator"
                                    name="Schedule"
                                    :rules="{required: scheduleUnitIsRequired, numeric: true}"
                                >
                                    <div
                                        :class="['form-group', {'is-invalid': scheduleNumberErrors.length}]"
                                    >
                                        <label>Schedule to Run</label>

                                        <input
                                            id="schedule"
                                            v-model.number="scheduleNumber"
                                            data-cy="scheduleNumber"
                                            type="number"
                                            step="1"
                                            min="0"
                                            :class="['form-control', {'is-invalid': scheduleNumberErrors.length}]"
                                            placeholder=""
                                            :disabled="scheduleNumberDisabled"
                                        >
                                        <div
                                            class="error-message text-danger mt-1"
                                        >
                                            {{ scheduleNumberErrors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-2">
                                <ValidationProvider
                                    v-slot="{errors: scheduleUnitErrors}"
                                    ref="scheduleUnitValidator"
                                    name="Frequency"
                                    :rules="{required: true}"
                                >
                                    <div
                                        :class="['form-group', {'is-invalid': scheduleUnitErrors.length}]"
                                    >
                                        <label>Frequency</label>

                                        <KCDropdown
                                            id="scheduleUnit"
                                            v-model="scheduleUnit"
                                            data-cy="scheduleUnit"
                                            :options="scheduleOptions "
                                        />
                                        <div
                                            class="error-message text-danger mt-1"
                                        >
                                            {{ scheduleUnitErrors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </form>
            </ValidationObserver>

            <Loading
                v-if="loading"
                key="loading"
                active
                loader=""
                class="paglipat-loading-component"
            >
                <FontAwesomeIcon
                    icon="spinner"
                    size="5x"
                    pulse
                    class="mb-2"
                />

                <template
                    slot="after"
                >
                    <h3
                        class="text-muted"
                    >
                        loading...
                    </h3>
                </template>
            </Loading>

            <div
                v-if="!loading"
                key="step"
            >
                <ValidationObserver
                    ref="stepValidationObserver"
                >
                    <PTransferStep
                        v-for="(step, index) in steps"
                        :key="index"
                        :step-index="index"
                        :step="step"
                        @delete-step="stepDelete($event)"
                        @updated="updateStep(step, $event)"
                    />
                </ValidationObserver>
            </div>

            <div
                v-if="!steps.length && !loading"
                key="msg"
                class="row mt-5 justify-content-center"
                data-cy="noTransferSteps"
            >
                <p class="text-muted">
                    There are no steps for this transfer&mdash;you'll need to add at least one step to continue.
                </p>
            </div>

            <RouterView />
        </div>
    </div>
</template>

<script>
    import KCDropdown from '@imt/vue-kit-car/src/components/Dropdown.vue';
    import toastMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import utils from '@imt/vue-toolbox/src/utils';
    import set from 'lodash/set';
    import snakeCase from 'lodash/snakeCase';
    import {mapActions, mapMutations, mapState} from 'vuex';


    import PBuilderToolbar from '@/components/PBuilderToolbar.vue';
    import PTransferStep from '@/components/PTransferStep.vue';

    export default {
        name: 'PTransferBuilder',
        components: {
            PBuilderToolbar,
            KCDropdown,
            Loading: () => import('vue-loading-overlay'),
            PTransferStep,
        },
        mixins: [toastMixin],
        data() {
            return {
                codeOverride: false,
                fetching: false,
                loading: false,
                scheduleNumber: 0,
                scheduleNumberDisabled: false,
                scheduleOptions: [
                    {
                        text: 'First of Month',
                        value: 'first_of_month',
                    },
                    {
                        text: 'Last of Month',
                        value: 'last_of_month',
                    },
                    {
                        text: 'Day(s)',
                        value: 'days',
                    },
                    {
                        text: 'Month(s)',
                        value: 'months',
                    },
                    {
                        text: 'Year(s)',
                        value: 'years',
                    },
                ],
                scheduleUnit: [],
                transferCode: '',
                transferTitle: '',
            };
        },
        computed: {
            transferId() {
                return this.$route.params.transferId;
            },
            scheduleUnitIsRequired() {
                if (this.scheduleUnit.length) {
                    return this.scheduleUnit[0].value === 'days' || this.scheduleUnit[0].value === 'months' || this.scheduleUnit[0].value === 'years';
                }

                return false;
            },
            ...mapState(['transfer']),
            ...mapState('builder', ['steps']),
            ...mapState('toolbox', [
                'permissions',
                'sideMenuCollapsed',
                'sideMenuHidden',
            ]),
        },
        watch: {
            scheduleNumber(newValue) {
                if (newValue < 0) {
                    this.scheduleNumber = 0;
                }
            },
            scheduleUnit(newValue) {
                if (newValue[0]?.value?.includes('of')) {
                    this.scheduleNumberDisabled = true;
                } else {
                    this.scheduleNumberDisabled = false;
                }
            },
            transferTitle(newValue) {
                if (!this.codeOverride) {
                    this.transferCode = snakeCase(newValue);
                }
            },
        },
        async created() {
            await this.init();
        },
        destroyed() {
            this.SET_DATA({
                field: 'transfer',
                data: {},
            });
        },
        methods: {
            async init() {
                if (this.transferId) {
                    await this.fetch();

                    let scheduleUnit = this.scheduleOptions.find(o => o.value === this.transfer.scheduleUnit);

                    this.transferCode = this.transfer.code;
                    this.transferTitle = this.transfer.title;
                    this.scheduleNumber = this.transfer.scheduleNumber;
                    this.scheduleUnit = scheduleUnit ? [scheduleUnit] : [];
                }
            },
            async createStep() {
                let direction = 'from';

                if (this.steps.length) {
                    direction = this.steps.slice(-1)[0].direction === 'to' ? 'from' : 'to';
                }

                await this.ADD_STEP({
                    direction: direction,
                    protocol: 'http',
                    retry: false,
                });
            },
            async fetch() {
                this.fetching = true;

                try {
                    await this.fetchTransfer(this.transferId);
                } catch (err) {
                    utils.console.log(err);
                    await this.$router.push({name: 'transfer.list'});
                }

                this.fetching = false;
            },
            async runTransferJob() {
                if (!this.steps.length) {
                    this.error('You must add at least one step to the transfer.', 'Run Transfer');
                }

                if (await this.$refs.stepValidationObserver.validate()) {
                    this.success('Running transfer. Please wait, this may take a minute', 'Run Transfer');

                    try {
                        await this.runTransfer(this.transferId);
                        this.success('Transfer successfully run!', 'Run Transfer');
                    } catch (e) {
                        this.error('Error running transfer!', 'Run Transfer');
                    }
                }
            },
            getValidationState({dirty, validated, valid = null}) {
                return dirty || validated ? valid : null;
            },
            async save() {
                let valid = await this.$refs.transferValidationObserver.validate();

                if (valid) {
                    this.loading = true;

                    try {
                        let resp = await this.saveTransfer({
                            id: this.transferId,
                            code: this.transferCode,
                            scheduleNumber: this.scheduleNumber,
                            scheduleUnit: this.scheduleUnit[0]?.value,
                            title: this.transferTitle,
                            type: 'Transfer',
                        });

                        this.success('Transfer saved successfully.', 'Save Transfer');

                        if (!this.transferId) {
                            await this.$router.push({
                                name: 'transfer.builder.edit',
                                params: {transferId: resp},
                            });
                        }
                    } catch (e) {
                        this.error('Error saving transfer, please try again.', 'Save Transfer');
                    }

                    this.loading = false;
                }
            },
            async stepDelete($event) {
                this.loading = true;

                try {
                    await this.deleteStep($event);

                    this.success('Transfer step successfully deleted.', 'Delete Step');
                } catch (e) {
                    this.error('Issue deleting step, try again.', 'Delete Step');
                }

                this.loading = false;
            },
            async transferDelete() {
                this.loading = true;

                try {
                    await this.deleteTransfer(this.transferId);

                    await this.$router.push({name: 'transfer.list'});
                    this.success('Transfer successfully deleted.', 'Delete Transfer');
                } catch (e) {
                    this.error('Issue deleting transfer, try again.', 'Delete Transfer');
                }

                this.loading = false;
            },
            updateStep(step, {
                key,
                value,
            }) {
                /* istanbul ignore next line */
                set(step, key, value);
            },
            ...mapActions([
                'fetchTransfer',
            ]),
            ...mapActions('builder', [
                'deleteStep',
                'deleteTransfer',
                'saveTransfer',
                'saveTransferSteps',
                'runTransfer',
            ]),
            ...mapMutations([
                'SET_DATA',
            ]),
            ...mapMutations('builder', [
                'ADD_STEP',
            ]),
        },
    };
</script>

<style lang="sass" scoped>
    @import '~@imt/vue-kit-car/src/sass/modules/transitions'
</style>
