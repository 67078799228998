<template>
    <div class="builder-toolbar container-fluid">
        <div class="row p-2">
            <div class="col-12 col-lg-auto px-0 px-lg-2 mb-2 mb-lg-0">
                <BButton
                    v-shortkey="{windows: ['ctrl', 'shift', 'r'], mac: ['meta', 'shift', 'r']}"
                    variant="primary"
                    class="w-100"
                    data-cy="runTransfer"
                    :disabled="saving || !stepExists"
                    @shortkey="$emit('run-transfer')"
                    @click="$emit('run-transfer')"
                >
                    <FontAwesomeIcon
                        icon="play"
                        class="mr-2"
                    />
                    Run Transfer
                </BButton>
            </div>

            <div class="mr-lg-auto col-12 col-lg-auto px-0 px-lg-2 mb-2 mb-lg-0">
                <RouterLink
                    v-if="!newTransfer && transferId"
                    :to="{name: 'transfer.activity.list', params: {transferId}}"
                    class="btn btn-primary w-100"
                >
                    <FontAwesomeIcon
                        icon="chart-line"
                        class="mr-2"
                    />
                    Activity
                </RouterLink>
            </div>

            <div
                class="col-12 col-lg-auto px-0 px-lg-2 mb-2 mb-lg-0"
                data-cy="addStep"
            >
                <BButton
                    variant="primary"
                    class="w-100"
                    @click="$emit('add-step')"
                >
                    <FontAwesomeIcon
                        icon="plus"
                        class="mr-2"
                    />
                    Add Step
                </BButton>
            </div>

            <div
                class="col-12 col-lg-auto px-0 px-lg-2 mb-2 mb-lg-0"
                data-cy="saveTransfer"
            >
                <BButton
                    v-shortkey="{windows: ['ctrl', 's'], mac: ['meta', 's']}"
                    :variant="saving ? 'warning' : 'primary'"
                    :disabled="saving"
                    class="w-100"
                    @shortkey="$emit('save-transfer')"
                    @click="$emit('save-transfer')"
                >
                    <FontAwesomeIcon
                        icon="save"
                        class="mr-2"
                    />
                    {{ transferId ? 'Update' : 'Save' }}
                </BButton>
            </div>

            <div
                class="col-12 col-lg-auto px-0 px-lg-2 mb-2 mb-lg-0"
                data-cy="deleteTransfer"
            >
                <BButton
                    id="deleteTransfer"
                    variant="primary"
                    class="w-100"
                    :disabled="saving || newTransfer"
                >
                    <FontAwesomeIcon
                        icon="trash"
                        class="mr-2"
                    />
                    Delete Transfer
                </BButton>

                <PopConfirm
                    target="deleteTransfer"
                    placement="bottom"
                    title="Are you sure you want to delete this transfer?"
                    @confirmed="$emit('delete-transfer')"
                />
            </div>

            <div class="col-12 col-lg-auto px-0 px-lg-2 mb-2 mb-lg-0">
                <RouterLink
                    :to="{name: 'transfer.list'}"
                    class="btn btn-primary w-100"
                    data-cy="backToTransfers"
                >
                    <FontAwesomeIcon
                        icon="stream"
                        class="mr-2"
                    />
                    Back to All Transfers
                </RouterLink>
            </div>
        </div>
    </div>
</template>

<script>
    import PopConfirm from '@imt/vue-kit-car/src/components/PopConfirm.vue';
    import {mapState} from 'vuex';

    import authMixin from '@/mixins/auth';

    export default {
        name: 'PBuilderToolbar',
        components: {
            PopConfirm,
        },
        mixins: [authMixin],
        computed: {
            newTransfer() {
                return this.$route.name === 'transfer.builder.add';
            },
            stepExists() {
                return this.steps.length > 0;
            },
            transferId() {
                return this.$route.params.transferId;
            },
            ...mapState('builder', [
                'saving',
                'steps'
            ]),
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    @import "~@imt/vue-kit-car/src/sass/kit-car"

    .builder-toolbar
        background-color: darken($kc-gray-100, 10%)
        position: sticky
        top: 0
        z-index: 1010
        width: 100%

        .clickable
            &:hover
                cursor: pointer

        .input-group-text
            &.clickable
                &:hover
                    background-color: var(--kc-gray-200)

    .configure-variables-input
        min-height: 30vh

    .mode
        &.dark
            .builder-toolbar
                background-color: var(--kc-blue-800)

                .input-group-text
                    &.clickable
                        &:hover
                            background-color: darken($kc-blue-700, 5%)
</style>
