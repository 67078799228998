<template>
    <div
        class="step"
        data-cy="step"
    >
        <div
            class="step-header"
            data-cy="stepHeader"
        >
            <div class="row">
                <div class="col-6 col-md-3 col-lg-2">
                    <div class="p-3">
                        <h1 data-cy="stepTitle">
                            Step {{ stepIndex + 1 }}
                        </h1>
                    </div>
                </div>

                <div class="col-auto ml-auto align-self-center mr-2">
                    <div class="mr-2">
                        <FontAwesomeIcon
                            :id="`deleteStep${stepIndex}`"
                            icon="times"
                            size="2x"
                            data-cy="deleteStepIcon"
                        />

                        <BTooltip
                            :target="`deleteStep${stepIndex}`"
                            triggers="hover"
                            placement="left"
                            title="Delete Step"
                        />

                        <PopConfirm
                            :target="`deleteStep${stepIndex}`"
                            placement="left"
                            title="Are you sure you want to delete this step?"
                            @confirmed="deleteStep"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div
            class="step-body"
            data-cy="stepBody"
        >
            <div class="row m-3">
                <div class="col-12 col-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <div
                                class="form-group"
                                data-cy="protocolType"
                            >
                                <label>Protocol</label>

                                <IMTUISelect
                                    :value="protocolType"
                                    :options="protocolOptions"
                                    @item-selected="toggleProtocol($event)"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div
                                class="form-group"
                                data-cy="directionChoice"
                            >
                                <label>Direction</label>

                                <IMTUISelect
                                    :value="directionChoice"
                                    :options="directionOptions"
                                    @item-selected="$emit('updated',{key: 'direction', value: $event})"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-lg-8">
                            <ValidationProvider
                                v-slot="{errors: locationUrlErrors}"
                                ref="locationValidator"
                                :name="(protocolType === 'http') ? 'URL' : 'Location'"
                                rules="required"
                            >
                                <div
                                    data-cy="locationUrl"
                                    :class="['form-group', {'is-invalid': locationUrlErrors.length}]"
                                >
                                    <label>{{ (protocolType === 'http') ? 'URL' : 'Location' }}</label>

                                    <input
                                        v-model.trim="locationUrl"
                                        type="text"
                                        :class="['form-control', {'is-invalid': locationUrlErrors.length}]"
                                        :placeholder="(protocolType === 'http') ? 'https://example.com/' : 'ftp://192.168.10.10/'"
                                        @input="$emit('updated',{key: 'url', value: $event.target.value})"
                                    >
                                    <div
                                        class="error-message text-danger mt-1"
                                    >
                                        {{ locationUrlErrors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-lg-8">
                            <ValidationProvider
                                v-slot="{errors: filenameErrors}"
                                ref="protocolValidator"
                                name="Filename"
                                :rules="{required: filenameIsRequired}"
                            >
                                <div
                                    v-if="protocolType === 'ftp'"
                                    :class="['form-group', {'is-invalid': filenameErrors.length}]"
                                    data-cy="filename"
                                >
                                    <label>Filename</label>

                                    <input
                                        v-model.trim="filename"
                                        :class="['form-control', {'is-invalid': filenameErrors.length}]"
                                        placeholder="example.pdf"
                                        @input="$emit('updated',{key: 'filename', value: $event.target.value})"
                                    >
                                    <div
                                        class="error-message text-danger mt-1"
                                    >
                                        {{ filenameErrors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-6 mt-3 mt-lg-0">
                    <div
                        v-if="protocolType === 'http'"
                    >
                        <h3>
                            Headers
                        </h3>
                        <div class="row">
                            <div class="col-12 col-lg-4">
                                <div
                                    data-cy="httpKey"
                                    class="form-group"
                                >
                                    <label>Key</label>

                                    <input
                                        v-model.trim="key"
                                        class="form-control"
                                        placeholder="Content-Type"
                                        @input="$emit('updated',{key: 'headers.key', value: $event.target.value})"
                                    >
                                </div>
                            </div>

                            <div class="col-12 col-lg-8">
                                <div
                                    class="form-group"
                                    data-cy="httpValue"
                                >
                                    <label>Value</label>

                                    <input
                                        v-model.trim="value"
                                        class="form-control"
                                        placeholder="application/json"
                                        @input="$emit('updated',{key: 'headers.value', value: $event.target.value})"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="protocolType === 'ftp'">
                        <div class="row">
                            <div class="col-12 col-lg-8">
                                <ValidationProvider
                                    v-slot="{errors: usernameErrors}"
                                    ref="usernameValidator"
                                    name="Username"
                                    :rules="{required: usernameIsRequired}"
                                >
                                    <div
                                        :class="['form-group', {'is-invalid': usernameErrors.length}]"
                                        data-cy="ftpUsername"
                                    >
                                        <label>Username</label>

                                        <input
                                            v-model.trim="username"
                                            type="text"
                                            :class="['form-control', {'is-invalid': usernameErrors.length}]"
                                            @input="$emit('updated',{key: 'username', value: $event.target.value})"
                                        >
                                        <div
                                            class="error-message text-danger mt-1"
                                        >
                                            {{ usernameErrors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-8">
                                <ValidationProvider
                                    v-slot="{errors: passwordErrors}"
                                    ref="passwordValidator"
                                    name="Password"
                                    :rules="{required: passwordIsRequired}"
                                >
                                    <div
                                        :class="['form-group', {'is-invalid': passwordErrors.length}]"
                                        data-cy="ftpPassword"
                                    >
                                        <label>Password</label>

                                        <input
                                            v-model.trim="password"
                                            type="password"
                                            :class="['form-control', {'is-invalid': passwordErrors.length}]"
                                            @input="$emit('updated',{key: 'password', value: $event.target.value})"
                                        >
                                        <div
                                            class="error-message text-danger mt-1"
                                        >
                                            {{ passwordErrors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <BFormGroup
                                data-cy="shouldRetry"
                                label="Retry"
                            >
                                <IMTUISelect
                                    :value="shouldRetry"
                                    :options="retryOptions"
                                    @item-selected="toggleRetry($event)"
                                />
                            </BFormGroup>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <ValidationProvider
                                v-slot="{errors: retryErrors}"
                                ref="retryValidator"
                                name="Retry"
                                :rules="{required: retryIsRequired, numeric: true}"
                            >
                                <div
                                    v-if="shouldRetry"
                                    :class="['form-group', {'is-invalid': retryErrors.length}]"
                                    data-cy="retryTimes"
                                >
                                    <label>Retry Times</label>

                                    <input
                                        v-model.number="retryTimes"
                                        :class="['form-control', {'is-invalid': retryErrors.length}]"
                                        type="number"
                                        step="1"
                                        min="0"
                                        @input="updateRetryTimes"
                                    >
                                    <div
                                        class="error-message text-danger mt-1"
                                    >
                                        {{ retryErrors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import IMTUISelect from '@imt/vue-imt-ui-select/src/components/IMTUISelect.vue';
    import PopConfirm from '@imt/vue-kit-car/src/components/PopConfirm.vue';
    import toastMixin from '@imt/vue-toolbox/src/mixins/toasts';

    export default {
        name: 'PTransferStep',
        components: {
            IMTUISelect,
            PopConfirm,
        },
        mixins: [toastMixin],
        props: {
            stepIndex: {
                type: Number,
                required: true,
            },
            step: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                directionChoice: '',
                filename: '',
                key: '',
                locationUrl: '',
                password: '',
                protocolOptions:[
                    {label: 'HTTP', value: 'http'},
                    {label: 'FTP', value: 'ftp'}
                ],
                protocolType: '',
                retryOptions: [
                    {label: 'Yes', value: true},
                    {label: 'No', value: false}
                ],
                retryTimes: 0,
                shouldRetry: false,
                username: '',
                value: ''
            };
        },
        computed: {
            directionOptions() {
                if (this.stepIndex === 0) {
                    return [{label: 'From', value: 'from'}];
                } else {
                    return [
                        {label: 'To', value: 'to'},
                        {label: 'From', value: 'from'}
                    ];
                }
            },
            filenameIsRequired() {
                return this.protocolType === 'ftp';
            },
            usernameIsRequired() {
                return this.protocolType === 'ftp';
            },
            passwordIsRequired() {
                return this.protocolType === 'ftp';
            },
            retryIsRequired() {
                return this.shouldRetry;
            },
        },
        async created() {
            await this.init();
        },
        methods: {
            deleteStep() {
                this.$emit('delete-step', {step: this.step, index: this.stepIndex});
            },
            async init() {
                this.directionChoice = this.stepIndex === 0 ? 'from' : this.step.direction;
                this.key = this.step.headers?.key;
                this.value = this.step.headers?.value;
                this.filename = this.step.filename;
                this.locationUrl = this.step.url;
                this.password = this.step.password;
                this.protocolType = this.step.protocol || 'http';
                this.retryTimes = this.step.retryTimes;
                this.shouldRetry = this.step.retry || false;
                this.username = this.step.username;
            },
            toggleProtocol($event) {
                this.protocolType = $event;
                this.key = '';
                this.value = '';
                this.username = '';
                this.password = '';
                this.filename = '';
                this.$emit('updated',{key: 'protocol', value: $event});
            },
            toggleRetry($event) {
                this.shouldRetry = $event;
                this.retryTimes = '';
                this.$emit('updated',{key: 'retry', value: $event});
            },
            updateRetryTimes() {
                if (this.retryTimes < 0) {
                    this.retryTimes = 0;
                }

                this.$emit('updated',{key: 'retry_times', value: this.retryTimes});
            }
        }
    };
</script>

<style
    lang="sass"
    scoped
>
    @import '~@imt/vue-kit-car/src/sass/kit-car'
    @import '~@imt/vue-kit-car/src/sass/modules/transitions'

    .step
        width: calc(100% - 2rem)
        display: flex
        flex-direction: column
        border-radius: 0.25rem
        margin: 1rem

        .step-header
            width: 100%
            border-radius: 0.25rem 0.25rem 0 0
            background-color: $primary
            color: white

            h1
                margin-bottom: 0 !important

            .fa-times:hover
                cursor: pointer

        .step-body
            width: 100%
            background-color: $secondary
            text-align: left

            .form-control
                border: 2px solid $kc-blue-100

            h3
                font-weight: bold

    .mode
        &.dark
            .step-body
                background-color: lighten($kc-blue-900, 5%)

            .step-header
                background: var(--kc-blue-600)
</style>
